/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useState, useContext } from 'react';
/// /// EXTERNAL /// ///
// mui //
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Grid,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
// components //
import { EnhancedDatePicker } from '@prosperia/atoms.enhanced-date-picker';
import { EnhancedTextField } from '@prosperia/atoms.enhanced-text-field';
import { EnhancedButton } from '@prosperia/atoms.enhanced-button';
// hook //
import { useResponsive } from '@prosperia/hooks.use-responsive';
// third party libraries //
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
// service //
import { reportApi } from 'services/reportService';
// context //
import { InteractionContext } from 'context/InteractionContext';
// components //
import CommonModal from '../common/CommonModal';

const RecaptchaModal = ({ setOpenModal }) => {
  const { interaction_id: interactionId, channelID, POC } = useParams();
  const { downSm, theme } = useResponsive();
  const primary = theme.palette.primary.main;
  const error = theme.palette.error.main;
  const { grey } = theme.palette;

  const [open, setOpen] = useState(true);
  const [openNotValidModal, setOpenNotValidModal] = useState(false);
  const [openMaximumAttemptsModal, setOpenMaximumAttemptsModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [birthDate, setBirthDate] = useState(null);

  const { secret, setInteraction } = useContext(InteractionContext);

  let titleVariant = 'h5';
  let containerWidth = '408px';
  let padding = '32px 48px 48px 48px';
  let subtitleVariant = 'body1';
  let gapContainer = '32px';
  if (downSm) {
    titleVariant = 'h6';
    containerWidth = '328px';
    padding = '32px';
    subtitleVariant = 'body2';
    gapContainer = '24px';
  }

  const responsive = {
    typography: {
      title: {
        variant: titleVariant,
        style: {
          fontWeight: 800,
          color: primary,
        },
      },
      subtitle: {
        variant: subtitleVariant,
        style: {
          fontWeight: 600,
          color: grey[600],
        },
      },
    },
    divider: {
      width: '53px',
      border: `2px solid ${primary}`,
      borderRadius: '4px',
    },
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: containerWidth,
      boxShadow: 24,
      borderRadius: '32px',
      outline: 'none',
    },
    paper: {
      display: 'flex',
      padding,
      borderRadius: '32px',
    },
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
    return true;
  };

  const handleReport = async () => {
    setShowLoader(true);
    const message = dayjs(birthDate).format('YYYY-MM-DD');
    const signature = CryptoJS.HmacSHA256(message, secret).toString(CryptoJS.enc.Hex);
    await reportApi(interactionId, channelID, POC, signature).then((reportInfo) => {
      const reportResult = reportInfo.data;
      let data;
      if (reportResult.output) data = reportResult.output;
      else data = reportResult.data;
      setInteraction(data);
      setShowLoader(false);
      setOpenModal(false);
    })
      .catch((errorReport) => {
        console.log('Unexpected Error get Reports', errorReport);
        const { status } = errorReport.response;
        if (status === 301) {
          window.location.assign(
            `${process.env.REACT_APP_CALCS_REPORT}/${channelID}/${POC}/${interactionId}`,
          );
        } else if (status === 403) {
          setOpenMaximumAttemptsModal(true);
          setShowLoader(false);
        } else if (status === 401) {
          setOpenNotValidModal(true);
          setShowLoader(false);
        } else setShowLoader(false);
      });
  };

  const disabled = errorDate || !birthDate;

  const commonResponsive = (responsiveData) => ({
    ...responsiveData,
    typography: {
      ...responsiveData.typography,
      title: {
        ...responsiveData.typography.title,
        style: {
          ...responsiveData.typography.title.style,
          color: error,
        },
      },
    },
  });

  return (
    <>
      <Modal
        data-cy="recaptcha-modal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={responsive.container}>
            <Paper elevation={0} sx={responsive.paper}>
              <Grid container gap={gapContainer}>
                <Grid container direction="column" item gap="12px">
                  <Typography
                    variant={responsive.typography.title.variant}
                    sx={responsive.typography.title.style}
                  >
                    Continuar al reporte
                  </Typography>
                  <Box sx={responsive.divider} />
                </Grid>
                <Grid container direction="column" item gap="8px">
                  <Typography
                    variant={responsive.typography.subtitle.variant}
                    sx={responsive.typography.subtitle.style}
                  >
                    Selecciona tu fecha de nacimiento para abrir tu reporte
                  </Typography>
                  <EnhancedDatePicker
                    views={['day', 'month', 'year']}
                    disableFuture
                    label="Día / Mes / Año"
                    inputFormat="DD/MM/YYYY"
                    value={birthDate}
                    onChange={(value) => {
                      const date = dayjs(value).toDate();
                      setBirthDate(date);
                    }}
                    onError={(err) => setErrorDate(err)}
                    renderInput={(inputParams) => (
                      <EnhancedTextField
                        data-cy="birth-date-field"
                        {...inputParams}
                        sx={{
                          width: '100%',
                          marginTop: '8px',
                          svg: { color: primary },
                        }}
                      />
                    )}
                  />
                </Grid>
                {showLoader
                  ? (
                    <Box sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    >
                      <CircularProgress size="1.875rem" />
                    </Box>
                  )

                  : (
                    <EnhancedButton
                      variant="primary"
                      data-cy="next-button"
                      disabled={disabled}
                      onClick={() => handleReport()}
                      customStyle={{
                        width: '100%',
                      }}
                    >
                      Continuar
                    </EnhancedButton>
                  )}

              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      {openNotValidModal && (
        <CommonModal
          backdrop={false}
          open={openNotValidModal}
          customResponsive={commonResponsive}
          icon={(
            <EventBusyOutlinedIcon
              color="error"
              sx={{ fontSize: 60, opacity: '0.5' }}
            />
          )}
          title="La fecha seleccionada no es válida"
          buttons={(
            <Grid container justifyContent="flex-end">
              <EnhancedButton
                variant="primary"
                data-cy="retrie-button"
                onClick={() => setOpenNotValidModal(false)}
                customStyle={{
                  width: 'auto',
                }}
              >
                Volver a intentar
              </EnhancedButton>
            </Grid>
          )}
        >
          <Typography
            variant={responsive.typography.subtitle.variant}
            sx={responsive.typography.subtitle.style}
            component="span"
          >
            La fecha de nacimiento seleccionada no coincide con la información guardada en el
            registro.
            Vuelve a intentarlo o &nbsp;
            <Typography
              component="a"
              style={{
                ...responsive.typography.subtitle.style,
                ...{ whiteSpace: 'nowrap' },
              }}
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdztXPq2MDhJNUXXQRvYYU55YBwMAZEQsIiDgpf274nBrpOBw/viewform?pli=1"
            >
              haz clic aquí
            </Typography>
            &nbsp; para solicitar
            el apoyo de nuestro equipo de soporte.
          </Typography>
        </CommonModal>
      )}
      {openMaximumAttemptsModal && (
        <CommonModal
          backdrop={false}
          open={openMaximumAttemptsModal}
          customResponsive={commonResponsive}
          setOpenMaximumAttemptsModal={setOpenMaximumAttemptsModal}
          icon={(
            <LockClockOutlinedIcon
              color="error"
              sx={{ fontSize: 60, opacity: '0.5' }}
            />
          )}
          title="Has alcanzado el número máximo de intentos"
          buttons={(
            <Grid container justifyContent="flex-end">
              <EnhancedButton
                variant="primary"
                data-cy="accept-button"
                onClick={() => setOpenMaximumAttemptsModal(false)}
                customStyle={{
                  width: 'auto',
                }}
              >
                Aceptar
              </EnhancedButton>
            </Grid>
          )}
        >
          <Typography
            variant={responsive.typography.subtitle.variant}
            sx={responsive.typography.subtitle.style}
            component="span"
          >
            Has intentado introducir una fecha errónea 3 veces, por lo
            que el acceso al reporte quedará bloqueado por 15 minutos.
            <br />
            Si crees que puede haber un error, ponte en contacto
            con soporte haciendo &nbsp;
            <Typography
              component="a"
              style={{
                ...responsive.typography.subtitle.style,
                ...{ whiteSpace: 'nowrap' },
              }}
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdztXPq2MDhJNUXXQRvYYU55YBwMAZEQsIiDgpf274nBrpOBw/viewform?pli=1"
            >
              clic aquí
            </Typography>
            .
          </Typography>
        </CommonModal>
      )}
    </>
  );
};

export default RecaptchaModal;
